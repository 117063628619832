import _ from 'lodash';
import clsx from 'clsx';
import {
  Box,
  Container,
  Typography,
  makeStyles,
  Button,
  Radio,
  FormControlLabel,
  RadioGroup
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { authSelector } from 'src/slices/auth';
import { ROUTES_AUTHEN } from 'src/constants';
import React, { useEffect, useState } from 'react';

import { getDomainTalent, getLoginToken } from 'src/utils/auth';
import LoaderLayer from 'src/components/LoaderLayer';
import { settingSelector } from 'src/slices/settings';

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    padding: '100px 5px 0'
  },
  registerBorderBottom: {
    borderBottom: '1px solid #919090'
  },
  wrapTitle: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    [theme.breakpoints.down(700)]: {
      flexDirection: 'column'
    }
  },
  registerTitle: {
    fontWeight: 'bold',
    fontSize: 22,
    marginBottom: 5,
    [theme.breakpoints.down('700')]: {
      fontSize: 18
    }
  },
  registerTitleText: {
    fontWeight: 'bold',
    fontSize: 20,
    [theme.breakpoints.down(700)]: {
      fontSize: '1rem'
    }
  },
  wrapTitleRight: {
    display: 'flex',
    flexDirection: 'row',
    '& p': {
      fontSize: 16,
      opacity: 0.3
    },
    [theme.breakpoints.down(700)]: {
      '& p': {
        fontSize: '0.8rem',
        marginTop: 10
      }
    }
  },
  registrationTitleActive: {
    color: '#000000',
    borderBottom: '2px solid #5D7BAB',
    opacity: '1 !important',
    marginRight: 20,
    [theme.breakpoints.down(700)]: {
      marginRight: 10
    }
  },
  titleRightText: {
    marginRight: 20,
    [theme.breakpoints.down(700)]: {
      marginRight: 10
    }
  },
  registerButton: {
    backgroundColor: 'black',
    padding: '12px 140px',
    borderRadius: 0,
    fontSize: 18,
    color: '#fff !important',
    '&:hover': {
      backgroundColor: '#1175ae'
    },
    [theme.breakpoints.down(700)]: {
      padding: '10px 80px'
    }
  },
  displayFlex: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down(700)]: {
      flexDirection: 'column',
      alignItems: 'start',
      display: 'block'
    }
  },
  displayFlexSP: {
    [theme.breakpoints.down(700)]: {
      display: 'flex',
      flexDirection: 'row'
    }
  },
  labelContainer: {
    width: '25%',
    justifyContent: 'flex-end',
    [theme.breakpoints.down(700)]: {
      width: '100%',
      justifyContent: 'flex-start',
      marginBottom: 14
    }
  },
  label: {
    fontWeight: 'bold',
    marginRight: 6
  },
  labelRequire: {
    backgroundColor: '#E02323',
    marginRight: 10,
    padding: '0 14px',
    color: '#ffffff',
    borderRadius: 12
  },
  radio: {
    fontSize: '1.2rem',
    color: '#333333',
    '&$checked': {
      color: '#333333'
    }
  },
  checked: {}
}));

function PaymentType() {
  const classes = useStyles();
  const navigate = useNavigate();
  const auth = useSelector(authSelector);
  const settings = useSelector(settingSelector);

  const [hover, setHover] = useState(false);
  const [paymentType, setPaymentType] = useState('1');

  useEffect(() => {
    const { members } = auth?.profile;
    const domainTalent = getDomainTalent();

    const myProfile = _.find(
      members,
      (member) => member.talent.domain.split('//')[1] === domainTalent
    );

    setPaymentType(myProfile?.payment_type);
  }, []);

  const handleNavigateByPaymentType = () => {
    switch (paymentType) {
      case '1':
        return navigate(ROUTES_AUTHEN.CHOOSE_CREDIT);
      case '2':
        return navigate(ROUTES_AUTHEN.MY_PAGE_PAYMENT_ATM);
      case '3':
        return navigate(ROUTES_AUTHEN.MY_PAGE_PAYMENT_STORE);
      case '4':
        return navigate(ROUTES_AUTHEN.MY_PAGE_PAYMENT_BANKING);
      default:
        break;
    }
  };

  return (
    <div className={classes.root}>
      {getLoginToken() && (
        <Container maxWidth="md">
          <Box mb={8} paddingBottom={window.innerWidth <= 700 ? 4 : 8}>
            <Box className={classes.wrapTitle}>
              <Typography className={classes.registerTitleText}>
                決済方法選択
              </Typography>
              <Box className={classes.wrapTitleRight}>
                <Typography className={classes.registrationTitleActive}>
                  決済方法選択
                </Typography>
                <Typography className={clsx(classes.titleRightText)}>
                  選択内容確認
                </Typography>
                <Typography className={classes.titleRightText}>
                  決済方法確認
                </Typography>
                <Typography>年会費決済</Typography>
              </Box>
            </Box>

            <Box
              sx={{ justifyContent: 'center' }}
              mb={5}
              mt={8}
              display="flex"
              alignItems="center"
              className={clsx(classes.registerBorderBottom)}
            >
              <Typography className={clsx(classes.registerTitle)}></Typography>
            </Box>

            <Box pb={5} className={classes.registerBorderBottom}>
              {/* Payment */}
              <Box display="flex" justifyContent="center" mb={2}>
                <Typography style={{ fontSize: 18 }}>
                  <strong>支払い金額　6,600円</strong>
                </Typography>
              </Box>
              <Box display="flex" justifyContent="center" mb={3}>
                <Typography style={{ fontSize: 18 }}>
                  <strong>
                    ファンクラブ会費入金月を1月めとし、12か月後の末日が有効期限となります
                  </strong>
                </Typography>
              </Box>

              <Box className={clsx(classes.displayFlex)}>
                <Box display={'flex'} className={clsx(classes.labelContainer)}>
                  <Typography className={clsx(classes.label)}>
                    支払い方法
                  </Typography>
                  <Typography className={clsx(classes.labelRequire)}>
                    必須
                  </Typography>
                </Box>
                <Box className={clsx(classes.displayFlex)}>
                  <RadioGroup
                    onChange={(event) => setPaymentType(event.target.value)}
                    value={paymentType}
                    row={window.innerWidth <= 700 ? false : true}
                  >
                    <FormControlLabel
                      value="1"
                      control={
                        <Radio
                          classes={{
                            root: classes.radio,
                            checked: classes.checked
                          }}
                        />
                      }
                      label="クレジットカード"
                    />
                    <FormControlLabel
                      value="2"
                      control={
                        <Radio
                          classes={{
                            root: classes.radio,
                            checked: classes.checked
                          }}
                        />
                      }
                      label="ATM決済"
                    />
                    <FormControlLabel
                      value="3"
                      control={
                        <Radio
                          classes={{
                            root: classes.radio,
                            checked: classes.checked
                          }}
                        />
                      }
                      label="コンビニ決済"
                    />
                    <FormControlLabel
                      value="4"
                      control={
                        <Radio
                          classes={{
                            root: classes.radio,
                            checked: classes.checked
                          }}
                        />
                      }
                      label="銀行ネット決済"
                    />
                  </RadioGroup>
                </Box>
              </Box>
              <Box className={clsx(classes.displayFlex)}>
                <Box
                  display={'flex'}
                  style={{ width: '30%' }}
                  className={clsx(classes.labelContainer)}
                ></Box>
                <Box className={clsx(classes.displayFlex)}>
                  <Typography>
                    ※クレジットカードをお選びいただき、クレジットカードの情報に変更がない場合は次回より自動更新となります。
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box mt={8} display="flex" sx={{ justifyContent: 'center' }}>
              <Button
                onClick={handleNavigateByPaymentType}
                onMouseOver={() => setHover(true)}
                onMouseOut={() => setHover(false)}
                style={{
                  backgroundColor: hover
                    ? settings?.settings?.theme_color1 + 'cc'
                    : settings?.settings?.theme_color1
                }}
                className={clsx(classes.registerButton)}
              >
                入力内容の確認
              </Button>
            </Box>
          </Box>
        </Container>
      )}
      <LoaderLayer loading={auth?.isFetching} />
    </div>
  );
}
export default PaymentType;
