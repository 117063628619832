import React from 'react';
import moment from 'moment';
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import { checkNameTalent, getDomainTalent } from 'src/utils/auth';
import { ROUTES } from 'src/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 1040,
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    '& p': {
      fontSize: 16
    }
  },
  topBanner: {
    width: '100%',
    background: '#B1B1B1',
    display: 'flex',
    height: 80
  },
  borderBottom: {
    padding: '0 5%',
    paddingBottom: '20px',
    marginBottom: '20px',
    borderBottom: '1px solid #707070'
  },
  textBigSize: {
    fontSize: '1.3rem'
  },
  dFlex: {
    display: 'flex'
  },
  addSpace: {
    whiteSpace: 'nowrap',
    marginRight: 10
  },
  heading: {
    fontSize: '2.8rem',
    lineHeight: 1,
    letterSpacing: 0,
    fontWeight: 400,
    marginBottom: '2.5rem',
    [theme.breakpoints.down(700)]: {
      fontSize: '2rem'
    },
    '&::before': {
      marginRight: '10px',
      content: '""',
      width: '30px',
      height: '30px',
      display: 'inline-block',
      backgroundColor: '#006eb1',
      [theme.breakpoints.down(700)]: {
        width: 22,
        height: 22
      }
    }
  },
  textWhite: {
    color: '#ffffff',
    marginTop: 'auto',
    marginLeft: 30,
    marginBottom: 'auto',
    fontSize: 25,
    [theme.breakpoints.up(700)]: {
      fontSize: 40
    }
  }
}));

function Terms() {
  const classes = useStyles();
  const { name, nameEng, nameLower } = checkNameTalent();

  const showPriceKYD = () => {
    const date = new Date();
    const domainTalent = getDomainTalent();

    if (
      domainTalent === process.env.REACT_APP_TALENT_KIM_YOUNG_DEA_DOMAIN &&
      moment(date).format('YYYY/MM/DD') <=
        moment('2023/01/31').format('YYYY/MM/DD')
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className={classes.root} title="会員規約">
      <div className={classes.topBanner}>
        <Typography className={classes.textWhite}>
          <strong style={{ fontSize: 30 }}>会員規約</strong>
        </Typography>
      </div>
      <br />
      <br />
      <Box className={classes.borderBottom}>
        <Typography>
          {name}{' '}
          ジャパンオフィシャルファンクラブにご入会いただく前に、以下の会員規約を必ず全てお読みください。規約には会員の権利と義務についての内容が含まれています。この規約に同意いただけない場合は、
          {name}{' '}
          ジャパンオフィシャルファンクラブへのお申込みを受付けることができません。また、ご入会いただいた方は、この会員規約（以下「本規約」といいます）に同意されたものとさせていただきます。
        </Typography>
        <br />
      </Box>
      <Box className={classes.borderBottom}>
        <Typography className={classes.textBigSize}>
          <strong>＜第1章 総則＞</strong>
        </Typography>
        <Typography>
          <strong>【第1条】（本団体の定義・目的）</strong>
        </Typography>
        <Typography>
          {name} ジャパンオフィシャルファンクラブ（英文表記：{nameEng} JAPAN
          OFFICIAL FANCLUB ）（以下「本団体」といいます）は、日本における{name}
          の公式ファンクラブとして
          {name}の活動を応援する事を目的とします。
        </Typography>
        <br />
        <Typography>
          <strong>【第2条】（規約）</strong>
        </Typography>
        <Typography>
          本規約は、本団体を運営する事務局である株式会社フラウ・インターナショナル（以下「事務局」といいます）が提供する本団体のサービス（以下「サービス」といいます）の基本的事項を規定します。
        </Typography>
        <br />
      </Box>
      <Box className={classes.borderBottom}>
        <Typography className={classes.textBigSize}>
          <strong>＜第2章　サービス利用契約＞</strong>
        </Typography>
        <Typography>
          <strong>【第3条】（利用契約の成立）</strong>
        </Typography>
        <Grid className={classes.dFlex}>
          <Typography className={classes.addSpace}>1.</Typography>
          <Typography>
            サービスの利用申込者は次の全てを満たすものとします。
            <br />
            <Grid className={classes.dFlex}>
              <Typography className={classes.addSpace}>1）</Typography>
              個人でのお申込であり、法人ではないこと
            </Grid>
            <Grid className={classes.dFlex}>
              <Typography className={classes.addSpace}>2）</Typography>
              本規約および入会案内にいずれも同意いただいたこと
            </Grid>
            <Grid className={classes.dFlex}>
              <Typography className={classes.addSpace}>3）</Typography>
              日本国内に居住していること
            </Grid>
            <Grid className={classes.dFlex}>
              <Typography className={classes.addSpace}>4）</Typography>
              サービスの利用申込および登録を重複して行っていないこと
            </Grid>
            <Grid className={classes.dFlex}>
              <Typography className={classes.addSpace}>5）</Typography>
              インターネットをご利用されない場合には、情報を受け取れないこと、情報伝達の遅れが生じる場合があること、会員特典の一部または全部を受けられないこと、およびサービスに関する十分なご案内ができないこと等に同意いただいたこと
            </Grid>
            <Grid className={classes.dFlex}>
              <Typography className={classes.addSpace}>6）</Typography>
              個人で楽しむ目的以外の目的（商業目的等）がないこと
            </Grid>
            <Grid className={classes.dFlex}>
              <Typography className={classes.addSpace}>7）</Typography>
              過去に事務局により会員資格を取り消されたことのないこと
            </Grid>
          </Typography>
        </Grid>
        <br />
        <Grid className={classes.dFlex}>
          <Typography className={classes.addSpace}>2.</Typography>
          <Typography>
            サービスの利用申込者は本規約に同意し、所定の方法により入会申込みフォームに必要事項を記載して送信することによって、サービスの利用申込みを行うものとします。
          </Typography>
        </Grid>
        <br />
        <Grid className={classes.dFlex}>
          <Typography className={classes.addSpace}>3.</Typography>
          <Typography>
            事務局において利用申込者の入会を承諾する場合は、利用申込者からの会費の納付を確認した後、サービス利用方法について電子メール、郵便もしくは他の方法で利用申込者に通知するものとします。事務局から利用申込者に対して上記通知がなされた時点においてサービスの利用契約（以下「利用契約」といいます）が成立するものとします。
          </Typography>
        </Grid>
        <br />
        <Grid className={classes.dFlex}>
          <Typography className={classes.addSpace}>4.</Typography>
          <Typography>
            未成年者が本団体への入会を希望する場合は、その保護者（親権者もしくは法定代理人）の同意を得た上で行うものとします。事務局は加入を希望する未成年者の保護者の同意を確認するため必要な姓名などの最小限の情報を要求することができます。
          </Typography>
        </Grid>
        <br />
        <Typography>
          <strong>【第4条】（会費及び会員資格期間）</strong>
        </Typography>
        <Grid className={classes.dFlex}>
          <Typography className={classes.addSpace}>1.</Typography>
          <Typography>
            利用申込者は入会申込時に本団体所定の年会費その他所定の費用（以下「会費等」といいます）を本団体の定める方法により支払うものとします。なお、会費等の支払が振込みにより行われる場合、振込手数料は利用申込者の負担となります。但し、利用申込者が本規約第3条に定める条件に合致しない場合、本団体において利用申込をお断りする場合がありますので予めご了承ください。
          </Typography>
        </Grid>
        <br />
        <Grid className={classes.dFlex}>
          <Typography className={classes.addSpace}>2.</Typography>
          <Typography>
            会員資格は事務局が当該会員についての入会手続きを完了した日の1日から翌年前月末までを有効期限とします。継続を希望される方は、会員期間満了月の20日までに次年度の会費を所定の方法にてご入金ください。但し、前年度クレジットカードで決済され、クレジットカードの情報に変更がない場合は、該当月の10日までに会員が退会手続きを行った場合を除きサービス利用契約が1年間自動更新されるものとします。
          </Typography>
        </Grid>
        <br />
        <Grid className={classes.dFlex}>
          <Typography className={classes.addSpace}>3.</Typography>
          <Typography>
            会員が退会しようとする場合には、会員本人が、本団体の会員専用ホームページ（以下「会員専用サイト」といいます）からの手続きまたは事務局への電話、メール等により事務局に申請するものとします。但し、この場合、支払い済みの会費等はいかなる理由があっても返還されません。また、本規約に基づき会員資格が取り消された会員について、支払い済みの会費等はいかなる理由があっても返還されません。
          </Typography>
        </Grid>
        {/* <Grid className={classes.dFlex}>
          <Typography className={classes.addSpace}>4</Typography>
          <Typography>
            規約に基づき会員資格が取り消された会員については、入金した年会費等はいかなる理由があっても返還されません。
          </Typography>
        </Grid> */}
        <br />
        <Typography>
          <strong>【第5条】（本団体からの通知）</strong>
        </Typography>
        <Grid className={classes.dFlex}>
          <Typography className={classes.addSpace}>1.</Typography>
          <Typography>
            本団体から会員への通知はオフィシャルサイト（https://{nameLower}.
            {nameLower === 'zo-insung' ? 'com' : 'jp'}
            /）（以下「本団体ホームページ」といいます）への掲載や発行物の送付、Eメールの配信など、本団体が適切と判断する方法で行います。
          </Typography>
        </Grid>
        <br />
        <Grid className={classes.dFlex}>
          <Typography className={classes.addSpace}>2.</Typography>
          <Typography>
            本団体からのEメールの配信は、サービス利用にあたり会員自らが登録・変更をしたEメールアドレスに対して配信するものとし、未登録・誤登録による不達について、本団体は一切の責任を負いません。
          </Typography>
        </Grid>
        <br />
        <Grid className={classes.dFlex}>
          <Typography className={classes.addSpace}>3.</Typography>
          <Typography>
            本団体からのEメールの配信は、事務局において会員自らが登録・変更をしたEメールアドレスに送信した時点で完了したものとします。会員がEメールを確認しない場合や通信状況による不達について、本団体および事務局は一切の責任を負いません。
          </Typography>
        </Grid>
        <br />
        <Typography>
          <strong>【第6条】（会員番号等の変更）</strong>
        </Typography>
        <Grid className={classes.dFlex}>
          <Typography className={classes.addSpace}>1.</Typography>
          <Typography>
            事務局が決定した会員番号は変更することができませんので、予めご了承ください。
          </Typography>
        </Grid>
        <br />
      </Box>
      <Box className={classes.borderBottom}>
        <Typography className={classes.textBigSize}>
          <strong>＜第3章　契約当事者の義務＞</strong>
        </Typography>
        <br />
        <Typography>
          <strong>【第7条】（事務局の義務）</strong>
        </Typography>
        <Grid className={classes.dFlex}>
          <Typography className={classes.addSpace}>1.</Typography>
          <Typography>
            事務局はサービスの提供にあたって、誠実にこれを提供いたします。事務局は会員からの合理的な手続きによって提起された意見や不満が妥当だと認める場合には適切に対応し処理します。処理に一定の期間が必要な場合は会員にその事由と処理の日程をお知らせします。
          </Typography>
        </Grid>
        <br />
        <Grid className={classes.dFlex}>
          <Typography className={classes.addSpace}>2.</Typography>
          <Typography>
            事務局は会員の個人情報を次の各号に定める目的に利用します。事務局による会員の個人情報の取り扱いは本規約の定めの他、
            <a
              href={'https://www.frau-inter.co.jp/privacy-policy/'}
              target="blank"
            >
              個人情報の取り扱いについて
            </a>
            に従うものとします。
            <Grid className={classes.dFlex}>
              <Typography className={classes.addSpace}>1）</Typography>
              <Typography>
                サービスの提供にあたり、ご連絡、商品の配送、料金清算等を行うため
              </Typography>
            </Grid>
            <Grid className={classes.dFlex}>
              <Typography className={classes.addSpace}>2）</Typography>
              <Typography>統計作成またはマーケット調査のため</Typography>
            </Grid>
          </Typography>
        </Grid>
        <br />
        <Grid className={classes.dFlex}>
          <Typography className={classes.addSpace}>3.</Typography>
          <Typography>
            事務局は次の各号に定める場合を除き、会員の事前の同意を得ることなく個人情報を第三者に提供しません。
            <Grid className={classes.dFlex}>
              <Typography className={classes.addSpace}>1）</Typography>
              <Typography>法令に基づく場合</Typography>
            </Grid>
            <Grid className={classes.dFlex}>
              <Typography className={classes.addSpace}>2）</Typography>
              <Typography>
                人の生命、身体又は財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき
              </Typography>
            </Grid>
            <Grid className={classes.dFlex}>
              <Typography className={classes.addSpace}>3）</Typography>
              <Typography>
                公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難であるとき
              </Typography>
            </Grid>
            <Grid className={classes.dFlex}>
              <Typography className={classes.addSpace}>4）</Typography>
              <Typography>
                国の機関若しくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることが困難であるとき
              </Typography>
            </Grid>
          </Typography>
        </Grid>
        <br />
        <Typography>
          <strong>【第8条】（会員(利用者)の義務）</strong>
        </Typography>
        <Grid className={classes.dFlex}>
          <Typography className={classes.addSpace}>1.</Typography>
          <Typography>
            会員は本規約および本団体または事務局が通知する利用案内や注意事項等を遵守する義務を負い、また、本団体、事務局および
            {name}の活動・業務を妨害する行為をしてはなりません。
          </Typography>
        </Grid>
        <br />
        <Grid className={classes.dFlex}>
          <Typography className={classes.addSpace}>2.</Typography>
          <Typography>
            会員番号、ログインＩＤとパスワードは第三者に知られないよう、十分注意のうえ管理してください。なお、会員番号、ログインＩＤおよびパスワードに関するすべての管理責任は会員本人にあります。管理の不備、不正な使用等により生じるすべての結果に対する責任は会員本人において負担するものとします。
          </Typography>
        </Grid>
        <br />
        <Grid className={classes.dFlex}>
          <Typography className={classes.addSpace}>3.</Typography>
          <Typography>
            会員が、サービスの利用権限、その他の利用契約上の地位を他人に譲渡又は貸与することは有償無償を問わずできません。また、それらを担保として提供することもできません。
          </Typography>
        </Grid>
        <br />
        <Grid className={classes.dFlex}>
          <Typography className={classes.addSpace}>4.</Typography>
          <Typography>
            会員本人の会員番号、ログインＩＤおよびパスワードが不正に使われたことまたは使われる恐れがあることを知った場合、会員は直ちにまた必ず事務局にその旨を知らせなければなりません。事務局に通知しなかったことおよび通知の遅延によって生じる損害は会員本人が負担しなければなりません。
          </Typography>
        </Grid>
        <br />
        {/* <Grid className={classes.dFlex}>
          <Typography className={classes.addSpace}>5</Typography>
          <Typography>
            会員本人の会員番号、ログインＩＤおよびパスワードが不正に使われたことまたは使われる恐れがあることを知った場合、会員は直ちにまた必ず事務局にその旨を知らせなければなりません。事務局に通知しなかったことおよび通知の遅延によって生じる損害は会員本人が負担しなければなりません。
          </Typography>
        </Grid> */}
        <Grid className={classes.dFlex}>
          <Typography className={classes.addSpace}>5.</Typography>
          <Typography>
            会員は、次の各行為を行ってはならないものとします。
            <br />
            1）会員証、会員番号、ログインＩＤおよびパスワードを有償無償問わず第三者に譲渡または貸与する行為
            <br />
            2）本団体のサービス運営や事務局の業務を妨害する行為
            <br />
            3）偽名や他人の名前を用いて本団体に利用申込をする行為
            <br />
            4）一人の会員において本団体に複数の利用申込をする行為
            <br />
            5）社会秩序に反する内容を流布する行為
            <br />
            6）公益に反する目的または社会的利益を妨害する目的でサービスの利用を計画し、もしくは利用する行為
            <br />
            7）他人の名誉を毀損する行為その他他人に不利益を与える行為
            <br />
            8）多量の情報を伝送し、または広告性の情報を伝送するなど、サービスの安定した運営を妨害する行為
            <br />
            9）情報通信設備の誤作動や情報の破壊を誘発させるコンピュータウィルスプログラムを流布する行為
            <br />
            10）{name}
            、本団体、事務局、他の会員、第三者の知的財産権その他の権利を侵害する行為
            <br />
            11）他人の個人情報、会員番号、ログインＩＤおよびパスワードを盗用し、または不正に使用する行為
            <br />
            12）本団体の運営および事務局のサービスを利用することで得た情報を事務局からの書面による事前承諾無しに複写したり、他のサイトに提供・掲示したりする行為、または商業的に利用する行為
            <br />
            13）本団体ホームページ又は第9条に定める会員専用サイト等に猥褻物を掲載し、または猥褻サイトへのリンクを設置する行為
            <br />
            14）営業行為および宗教団体の布教・勧誘行為、また政治団体の宣伝行為
            <br />
            15）会員特典およびチケットその他の本団体の運営により提供されるサービスまたはサービスによって得た物品等を転売する行為
            <br />
            16）{name}の名誉や品位を毀損し、または毀損する恐れのある行為
            <br />
            17）法令、行政指導、本規約、道徳、慣習に反する行為
            <br />
            18）その他本団体または事務局が合理的事由により不適切だと判断し、禁止する行為
            <br />
          </Typography>
        </Grid>
        <br />
        <Grid className={classes.dFlex}>
          <Typography className={classes.addSpace}>6.</Typography>
          <Typography>
            会員は利用申込みの際に記載した事項が変更された場合には、直ちに本団体の会員専用サイトから自ら修正するか、事務局に変更内容を知らせ、会員情報を修正しなければなりません。会員が会員情報を修正しない場合や修正が遅延した場合に会員が受ける不利益について、本団体および事務局は一切責任を負いません。
          </Typography>
        </Grid>
      </Box>
      <Box className={classes.borderBottom}>
        <Typography className={classes.textBigSize}>
          <strong>＜第4章　サービスの利用＞</strong>
        </Typography>
        <br />
        <Typography>
          <strong>【第9条】（サービス利用の範囲）</strong>
        </Typography>
        <Grid className={classes.dFlex}>
          <Typography className={classes.addSpace}>1.</Typography>
          <Typography>
            会員は本団体に入会することにより、事務局が提供する会員専用サイトの閲覧その他所定のサービスを利用することができます。但し、会員は、
            {name}
            の事情、事務局の事情または不可抗力等により事前の通知なくサービス内容に変更があり得ることを予め了承するものとします。
          </Typography>
        </Grid>
        <br />

        <Typography>
          <strong>【第10条】（ポイント）</strong>
        </Typography>
        <Grid className={classes.dFlex}>
          <Typography className={classes.addSpace}>1.</Typography>
          <Typography>
            ポイントは、以下の場合に、事務局が定める付与率に従い会員に付与されます。
            <br />
            1）会員が所定の手続きによりポイントを購入した場合
            <br />
            2）事務局が提供する特典等としてポイントの付与がなされる場合
          </Typography>
        </Grid>
        <br />
        <Grid className={classes.dFlex}>
          <Typography className={classes.addSpace}>2.</Typography>
          <Typography>
            会員は、正会員登録期間中に限り、ポイントを事務局が指定する特定のサービスまたはコンテンツと交換して利用することができるものとします。会員において事務局が指定するサービスまたはコンテンツ以外とポイントを交換することはできません。サービスまたはコンテンツとの交換に必要なポイント数や交換条件は事務局が定め、所定の方法により表示します。
          </Typography>
        </Grid>
        <br />
        <Grid className={classes.dFlex}>
          <Typography className={classes.addSpace}>3.</Typography>
          <Typography>
            ポイントの有効期限については事務局が定め、所定の方法により表示します。
          </Typography>
        </Grid>
        <br />
        <Grid className={classes.dFlex}>
          <Typography className={classes.addSpace}>4.</Typography>
          <Typography>
            ポイントは、ポイント制度を廃止する場合や法令上必要となる場合を除き、払い戻しされません。
          </Typography>
        </Grid>
        <br />
        <Grid className={classes.dFlex}>
          <Typography className={classes.addSpace}>5.</Typography>
          <Typography>
            ポイントは、これを取得したアカウントでのみ利用できるものとします。
          </Typography>
        </Grid>
        <br />

        <Typography>
          <strong>【第11条】（会員の掲示物など）</strong>
          <br />
          事務局は、会員が次の各行為を行うことを禁止いたします。また、このような行為が行われた場合、本団体は当該会員を強制退会処分とすることができ、また本団体ホームページ（会員専用サイトを含みます。以下同じ）への書込内容または掲示する内容物が次の各項目にあてはまると判断した場合は事前の通知なく削除することができます。
          <Grid className={classes.dFlex}>
            <Typography className={classes.addSpace}>1）</Typography>
            <Typography>
              {name}
              、本団体、事務局、他の会員又は第三者を誹謗・中傷するなどしてこれらの当事者の名誉を毀損する可能性がある行為
            </Typography>
          </Grid>
          <Grid className={classes.dFlex}>
            <Typography className={classes.addSpace}>2）</Typography>
            <Typography>社会秩序に反する行為</Typography>
          </Grid>
          <Grid className={classes.dFlex}>
            <Typography className={classes.addSpace}>3）</Typography>
            <Typography>犯罪行為その他犯罪に繋がると認められる行為</Typography>
          </Grid>
          <Grid className={classes.dFlex}>
            <Typography className={classes.addSpace}>4）</Typography>
            <Typography>
              本団体、事務局が規定した掲示期間を超過して掲示等をする行為
            </Typography>
          </Grid>
          <Grid className={classes.dFlex}>
            <Typography className={classes.addSpace}>5）</Typography>
            <Typography>
              本団体ホームページに猥褻物を掲示する行為および猥褻サイトへのリンクを設置する行為
            </Typography>
          </Grid>
          <Grid className={classes.dFlex}>
            <Typography className={classes.addSpace}>6）</Typography>
            <Typography>
              通称「海賊版」と呼ばれる不法な複製品を取り扱う事業者の情報・資料を掲示する行為
              <br />
              その他本団体、事務局の営業に支障を生じさせる行為
            </Typography>
          </Grid>
          <Grid className={classes.dFlex}>
            <Typography className={classes.addSpace}>7）</Typography>
            <Typography>
              事実に反する内容や適切な事実確認がなされていない情報・資料を本団体ホームページに掲示する行為
              <br />
              その他会員に不利益が生じる可能性がある情報・資料を本団体ホームページに掲示する行為
            </Typography>
          </Grid>
          <Grid className={classes.dFlex}>
            <Typography className={classes.addSpace}>8）</Typography>
            <Typography>
              その他本団体または事務局が合理的事由により不適切だと判断し、禁止した行為
            </Typography>
          </Grid>
        </Typography>
        <br />
        <Typography>
          <strong>【第12条】（団体のホームページにおけるサービス）</strong>
        </Typography>
        <Grid className={classes.dFlex}>
          <Typography className={classes.addSpace}>1.</Typography>
          <Typography>
            本団体および事務局は会員により掲載された内容・伝送されたメッセージ・その他の通信メッセージなどの通信データを保管する義務を負いません。
          </Typography>
        </Grid>
        <br />
        <Grid className={classes.dFlex}>
          <Typography className={classes.addSpace}>2.</Typography>
          <Typography>
            次の各事由が生じた場合、事務局は本団体ホームページにおけるサービスの提供を中止することができます。
            <Grid className={classes.dFlex}>
              <Typography className={classes.addSpace}>1）</Typography>
              <Typography>
                サービス用の設備補修のための工事など、やむを得ない事情がある場合
              </Typography>
            </Grid>
            <Grid className={classes.dFlex}>
              <Typography className={classes.addSpace}>2）</Typography>
              <Typography>
                何らかの理由によって電気通信サービスが中止された場合
              </Typography>
            </Grid>
            <Grid className={classes.dFlex}>
              <Typography className={classes.addSpace}>3）</Typography>
              <Typography>
                その他の不可抗力によって中止が不可避である場合
              </Typography>
            </Grid>
          </Typography>
        </Grid>
        <br />
        <Grid className={classes.dFlex}>
          <Typography className={classes.addSpace}>3.</Typography>
          <Typography>
            本団体のサービス提供に問題が生じ、一時的にサービスを中止しなければならない場合、事務局はサービス中止の5日前までに会員に告知した上、サービスを中止することができます。なお、やむを得ない事情のある場合、事務局は事前告知期間を変更または省略することができるものとします。上記のサービス中止により会員に何等かの損害が生じた場合、事務局に故意又は重過失がある場合を除き、本団体および事務局が負担する損害賠償の総額は年会費額を上限とします。
          </Typography>
        </Grid>
        <br />
        <Grid className={classes.dFlex}>
          <Typography className={classes.addSpace}>4.</Typography>
          <Typography>
            事務局は5日前までに会員に告知した上、サービスを一時的に修正、変更および中断することができます。上記のサービスの一時的な修正、変更又は中断により会員に何等かの損害が生じた場合、事務局に故意又は重過失がある場合を除き、本団体および事務局が負担する損害賠償の総額は年会費額を上限とします。
          </Typography>
        </Grid>
        <br />
        <Grid className={classes.dFlex}>
          <Typography className={classes.addSpace}>5.</Typography>
          <Typography>
            利用者が本規約の内容に違反する行為に及んだ場合、事務局は当該利用者のサービスの使用を制限又は中止することができます。また、この場合、事務局は利用者が本団体ホームページに掲示した内容の全部もしくは一部を削除することができ、利用者のサービスへの接続を一定の期間または期間を定めることなく禁止することができるものとします。
          </Typography>
        </Grid>
        <br />
      </Box>
      <Box className={classes.borderBottom} style={{ borderBottom: 'none' }}>
        <Typography className={classes.textBigSize}>
          <strong>＜第5章　その他の事項＞</strong>
        </Typography>
        <br />
        <Typography>
          <strong>【第13条】（免責条項）</strong>
        </Typography>
        <Grid className={classes.dFlex}>
          <Typography className={classes.addSpace}>1.</Typography>
          <Typography>
            天災地変、法令・行政指導・監督官庁の指導、事故、{name}
            の疾病、不可抗力等によりサービスを提供することができない場合において、本団体および事務局は一切の責任を負わないものとします。
          </Typography>
        </Grid>
        <br />
        <Grid className={classes.dFlex}>
          <Typography className={classes.addSpace}>2.</Typography>
          <Typography>
            事務局と{name}
            所属会社との契約が終了したときは、本サービスは当然に終了するものとし、事務局において速やかに会員にその旨を通知した上でサービス終了の処理を行うものとします。
          </Typography>
        </Grid>
        <br />
        <Grid className={classes.dFlex}>
          <Typography className={classes.addSpace}>3.</Typography>
          <Typography>
            本団体および事務局は会員に起因する事由によるサービス利用の障害について責任を負いません。
          </Typography>
        </Grid>
        <br />
        <Grid className={classes.dFlex}>
          <Typography className={classes.addSpace}>4.</Typography>
          <Typography>
            本団体および事務局は会員がサービスの利用を通じて得た情報・資料等の正確性・安全性・完全性・有用性について保証するものでなく、会員がこれらの情報等を利用したことに伴う損害に関して責任を負いません。
          </Typography>
        </Grid>
        <br />
        <Grid className={classes.dFlex}>
          <Typography className={classes.addSpace}>5.</Typography>
          <Typography>
            本団体および事務局は、会員個人のネットワーク利用環境により生じる可能性のある会員間のサービス利用の満足度の差について責任を負いません。
          </Typography>
        </Grid>
        <br />
        <Grid className={classes.dFlex}>
          <Typography className={classes.addSpace}>6.</Typography>
          <Typography>
            事務局は本団体のサービス利用を通じて会員が利用するコンピュータおよびネットワーク環境により発生したどのような損害に対しても責任を負いません。
          </Typography>
        </Grid>
        <br />
        <Grid className={classes.dFlex}>
          <Typography className={classes.addSpace}>7.</Typography>
          <Typography>
            本団体および事務局は、本団体ホームページにリンクが設置された他サイトにおける記載内容や、他サイトにおける会員の言動等について、責任を負いません。
          </Typography>
        </Grid>
        <br />
        <Typography>
          <strong>【第14条】（本規約の効力および変更）</strong>
        </Typography>
        <Grid className={classes.dFlex}>
          <Typography className={classes.addSpace}>1.</Typography>
          <Typography>
            本規約は本団体ホームページへの掲示その他の適切な方法で会員に告知することにより、事務局と会員の契約の内容として効力を生じるものとします。
          </Typography>
        </Grid>
        <br />
        <Grid className={classes.dFlex}>
          <Typography className={classes.addSpace}>2.</Typography>
          <Typography>
            事務局は、必要な場合、本規約を変更することができます。事務局は本規約を変更する場合、当該変更後の規約につき適用日を明記した上、現行の規約とともにその適用日の7日前から適用日の前日までの間、本団体ホームページに掲示する方法その他の適切な方法により告知します。
          </Typography>
        </Grid>
        <br />
        <Grid className={classes.dFlex}>
          <Typography className={classes.addSpace}>3.</Typography>
          <Typography>
            会員が変更された規約に同意しない場合はサービス利用を中断し、退会することができます。但し、この場合支払済みの会費等は返還されません。
          </Typography>
        </Grid>
        <br />
        <Grid className={classes.dFlex}>
          <Typography className={classes.addSpace}>4.</Typography>
          <Typography>
            変更された規約の適用日時以後にも会員において継続的にサービスを利用した場合は、規約の変更事項に同意したものと見なします。
          </Typography>
        </Grid>
        <br />
        <Typography>
          <strong>【第15条】（強制退会）</strong>
          <br />
          会員が本規約に違反した場合、本団体は事前に通知することなく会員を強制退会処分とすることができます。この場合、支払い済みの会費等は一切返還いたしません。
          <br />
          <br />
          <strong>【第16条】（紛争解決）</strong>
          <br />
          サービスに関して、本規約により解決できない問題が生じたときは、本団体と会員との間で誠意をもって話し合い、これを解決するものとします。なお、紛争を訴訟によって解決するときには、大阪地方裁判所を第一審の管轄裁判所とします。
          <br />
          <br />
          <br />
          附則
          <br />
          （実施期日）この規約は、
          {nameLower === 'kim-youngdae'
            ? '2022年2月14日'
            : nameLower === 'yoo-seungho'
            ? '2020年3月2日'
            : nameLower === 'song-joongki'
            ? '2020年9月1日'
            : nameLower === 'zo-insung'
            ? '2008年9月9日'
            : nameLower === 'lee-jongsuk'
            ? '2015年3月20日'
            : nameLower === 'kim-jiwon'
            ? '2024年8月1日'
            : nameLower === 'park-minyoung' && '2024年9月12日'}
          から施行します。
          <br />
          {nameLower === 'yoo-seungho'
            ? '2023年1月27日　一部改定'
            : nameLower === 'zo-insung'
            ? '2019年4月1日　一部追記'
            : nameLower === 'lee-jongsuk' && '2019年4月1日　一部追記'}
          <br />
          {nameLower === 'kim-youngdae'
            ? '2023年1月24日　一部改定'
            : nameLower === 'zo-insung'
            ? '2020年4月1日　一部追記'
            : nameLower === 'lee-jongsuk' && '2020年4月1日　一部追記'}
          <br />
          {nameLower === 'zo-insung'
            ? '2020年7月10日 一部改定'
            : nameLower === 'lee-jongsuk' && '2020年7月10日　一部改定'}
          <br />
        </Typography>
        <br />
        <br />
        <br />
      </Box>
    </div>
  );
}

export default Terms;
